/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    src: local("Proxima Nova Regular"),
        url("./fonts/ProximaNova/ProximaNova-Regular.woff") format("woff");
}

@font-face {
    font-family: "Proxima Nova Thin";
    font-style: normal;
    font-weight: normal;
    src: local("Proxima Nova Thin"),
        url("./fonts/ProximaNova/ProximaNovaT-Thin.woff") format("woff");
}

@font-face {
    font-family: "Proxima Nova Light";
    font-style: normal;
    font-weight: normal;
    src: local("Proxima Nova Light"),
        url("./fonts/ProximaNova/ProximaNova-Light.woff") format("woff");
}

@font-face {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    src: local("Proxima Nova Semibold"),
        url("./fonts/ProximaNova/ProximaNova-Semibold.woff") format("woff");
}

@font-face {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: bold;
    src: local("Proxima Nova Bold"),
        url("./fonts/ProximaNova/ProximaNova-Bold.woff") format("woff");
}

@font-face {
    font-family: "Proxima Nova Extrabold";
    font-style: normal;
    font-weight: normal;
    src: local("Proxima Nova Extrabold"),
        url("./fonts/ProximaNova/ProximaNova-Extrabld.woff") format("woff");
}

@font-face {
    font-family: "Proxima Nova Black";
    font-style: normal;
    font-weight: normal;
    src: local("Proxima Nova Black"),
        url("./fonts/ProximaNova/ProximaNova-Black.woff") format("woff");
}

/* AVENIR */

@font-face {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: normal;
    src: local("Avenir Next Regular"),
        url("./fonts/AvenirNext/AvenirNextCyr-Regular.woff") format("woff");
}

@font-face {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: 600;
    src: local("Avenir Next Medium"),
        url("./fonts/AvenirNext/AvenirNextCyr-Medium.woff") format("woff");
}

@font-face {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: bold;
    src: local("Avenir Next Bold"),
        url("./fonts/AvenirNext/AvenirNextCyr-Bold.woff") format("woff");
}

/* JOST */

@font-face {
    font-family: "Jost-Regular";
    src: url("./fonts/Jost/Jost-Regular.ttf");
}
@font-face {
    font-family: "Jost-SemiBold";
    src: url("./fonts/Jost/Jost-SemiBold.ttf");
}
@font-face {
    font-family: "Jost-Medium";
    src: url("./fonts/Jost/Jost-Medium.ttf");
}
@font-face {
    font-family: "RobotoMono-Medium";
    src: url("./fonts/RobotoMono-Medium.ttf");
}
