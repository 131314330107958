@import "~antd/dist/antd.css";
@import "./fonts.css";

/* App general styles */

html,
body {
    /* stylelint-disable-next-line */
    font-family: "Jost-Regular", "Proxima Nova", -apple-system,
        BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    overflow: hidden;

    color: #333333;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#root {
    min-height: 100vh;
    height: auto;
    background-color: inherit;
}

.arrow-up {
    transform: rotate(180deg);
}
.arrow-left {
    transform: rotate(90deg);
}
.arrow-left {
    transform: rotate(-90deg);
}
